<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Category List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-debounce:300ms="getItems"
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.results"
        :server-items-length="items.totalResults"
        :options.sync="pagination"
        :footer-props="{'items-per-page-options':[30, 45, 60, -1]}"
        :loading="loading"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:item.action="{item}">
          <v-btn color="grey darken-2" icon dark :to="'/category/edit/'+item.id">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="grey darken-2" icon dark @click="confirmDelete(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!-- Delete confirmation -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5">Are you sure delete <b>{{category.name}}</b>?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="red darken-4" text @click="deleteItem(category)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn color="primary" fixed fab bottom right to="/category/add">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { parseParams } from "../../plugins/helper";
export default {
  name: "Categorylist",
  mounted(){
    this.getItems()
  },
  data() {
    return {
      loading: false,
      dialog: false,
      category: {
        name: null,
      },
      search: "",
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      pagination: {
        itemsPerPage: 30,
        sortBy: ["name"],
        page: 1,
        filters: {
        },
      },
      headers: [
        { text: "Category Name", value: "name" },
        { text: "Parent Category", value: "parent.name" },
        { text: "Action", value: "action", sortable: false },
      ],
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    showPreview(item) {
      this.preview_data = item;
      this.show_preview = true;
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios.get("/admin/category/list?"+url)
        .then((response) => {
          _self.items = response.data.data;
          console.log(_self.items)
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item) {
      this.dialog = true;
      this.category = item;
    },
    deleteItem(item) {
      const index = this.items.results.indexOf(item);
      let _self = this;
      _self.loading = "secondary";
      this.$axios.delete("/admin/category/delete/"+item.id)
        .then((res) => {
          if (res.status) {
            _self.items.results.splice(index, 1);
            _self.dialog = false;
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
  },
};
</script>